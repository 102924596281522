import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import useOnClickOutside from './useOnClickOutSide';

type PopoverProps = {
  className?: string;
  handler: React.ReactNode;
  content: React.ReactNode;
  direction?: 'left' | 'right';
  handleToggle?: () => void;
  appendTobody?: boolean;
};

const Popover: React.FC<PopoverProps> = ({ className, handler, content, direction }) => {
  // Popover State
  const [state, setState] = useState<boolean>(false);

  // Popover Append to body
  const [portalElement, setPortalElement] = useState<any>(undefined);

  // Ref
  const ref = useRef(null);

  // Add all classs to an array
  const addAllClasses: string[] = ['popover-wrapper'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  // Add direction class on popover content
  if (direction) {
    addAllClasses.push(direction);
  }

  // Toggle Popover content
  const handleToggle = (e: any) => {
    e.stopPropagation();
    setState(!state);
  };

  // Handle document click
  const handleDocumentClick = (e: any) => {
    e.stopPropagation();
    if (state) {
      const domNode = ReactDOM.findDOMNode(ref.current);

      if (!domNode || !domNode.contains(e.target)) {
        handleToggle(e);
      }
    }
  };

  // Handle window event listener
  useEffect(() => {
    window.addEventListener('click', handleDocumentClick);
    return () => {
      window.removeEventListener('click', handleDocumentClick);
    };
  });

  // Close popover on click outside
  useOnClickOutside(ref, () => setState(false));

  return (
    <div className={addAllClasses.join(' ')} ref={ref}>
      <div className={`popover-handler ${state ? 'active' : ''}`} onClick={handleToggle}>
        {handler}
      </div>
      {state && <div className="popover-content dark:dark-shadow">{content && <div className="inner-wrap">{content}</div>}</div>}
    </div>
  );
};

export default Popover;
