import React from 'react';
import Link from 'next/link';
import Popover from '../atoms/popover/popover';
import { Icon } from '@iconify/react-with-api';
import { ICampaignStatus } from 'interface';
import { MoreSquare, PaperPlus, Show } from 'react-iconly';
import { oneLine } from 'common-tags';

type CampaignCardPopOverProps = {
  uuid: string;
  status: ICampaignStatus;
  otherClasses?: string;
};
const CategoryMenu = ({ uuid, status }: CampaignCardPopOverProps) => {
  const linkContainer = oneLine`
  flex items-center
`;

  const linkClasses = oneLine`
text-dark-500
dark:text-dark-400
text-sm
font-medium
pl-2
`;

  return (
    <ul className="popoever-cart-menu-items">
      <li>
        <Link href={`${status === 'draft' ? '/publish/basic/?cid=' : '/campaign/'}${uuid}`} prefetch={false}>
          <a className={linkContainer}>
            <Show set="light" size="small"  />
            <span className={linkClasses}>View</span>
          </a>
        </Link>
      </li>
      {status !== 'draft' && (
        <li>
          <Link href={`/publish/basic?id=${uuid}`} prefetch={false}>
            <a className={linkContainer}>
              <PaperPlus set="light" size="small" />
              <span className={linkClasses}>Duplicate</span>
            </a>
          </Link>
        </li>
      )}
    </ul>
  );
};

const CampaignCardPopOver = ({ uuid, status, otherClasses }: CampaignCardPopOverProps) => {
  return (
    <Popover
      className={`left ${otherClasses ? otherClasses : ''}`}
      handler={
        <span className="block p-2 transition-all duration-300 ease-in-out bg-white cursor-pointer rounded-2xl">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.21052 8.4209C3.2842 8.4209 2.52631 9.17879 2.52631 10.1051C2.52631 11.0314 3.2842 11.7893 4.21052 11.7893C5.13683 11.7893 5.89473 11.0314 5.89473 10.1051C5.89473 9.17879 5.13683 8.4209 4.21052 8.4209Z"
              fill="#030314"
            />
            <path
              d="M16 8.4209C15.0737 8.4209 14.3158 9.17879 14.3158 10.1051C14.3158 11.0314 15.0737 11.7893 16 11.7893C16.9263 11.7893 17.6842 11.0314 17.6842 10.1051C17.6842 9.17879 16.9263 8.4209 16 8.4209Z"
              fill="#030314"
            />
            <path
              d="M10.1053 8.4209C9.17898 8.4209 8.42108 9.17879 8.42108 10.1051C8.42108 11.0314 9.17898 11.7893 10.1053 11.7893C11.0316 11.7893 11.7895 11.0314 11.7895 10.1051C11.7895 9.17879 11.0316 8.4209 10.1053 8.4209Z"
              fill="#030314"
            />
          </svg>
        </span>
      }
      content={<CategoryMenu uuid={uuid} status={status} />}
    />
  );
};
export default CampaignCardPopOver;
