import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { setCookie } from 'nookies';
import * as Sentry from '@sentry/nextjs';

import AppReducer, { AppAction, appInitialState, AppState } from 'context/AppReducer';
import { ToastContainer, toast } from 'react-toastify';
import { ToastComponent, NotifyType } from './common/atoms/Toast';
import { useRouter } from 'next/router';
import { MapJsonParsed } from 'interface/ILocation';
import { NotificationProvider } from './common/molecules/Notifications';

if (typeof window !== 'undefined') {
  LogRocket.init('xc3wci/portal-v5', {
    dom: {
      privateClassNameBlocklist: ['input-s'],
    },
    network: {
      requestSanitizer(request: any) {
        if (
          request.url.includes(
            '/auth' ||
              request.url.includes('companyupdate') ||
              request.url.includes('/users/update-profile') ||
              request.url.includes('/users/me')
          )
        ) {
          return null;
        }
        return request;
      },
    },
  });
  // plugins should also only be initialized when in the browser
  setupLogRocketReact(LogRocket);
  LogRocket.getSessionURL(sessionURL => {
    // @ts-ignore
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}
export interface MapPin {
  data: {
    type: string;
    features: [];
  };
}

const AppContext = createContext<{
  user: null;
  state: AppState;
  setUser: any;
  globalLocations: MapJsonParsed;
  reloadJwt: number;
  setReloadJwt: (value: number) => void;
  setGlobalLocations: (locations: MapJsonParsed) => void;
  dispatch: React.Dispatch<AppAction>;
  notify: (message: string, type: NotifyType, position?: string, headline?: string, autoClose?: boolean) => void;
}>({
  user: null,
  state: appInitialState,
  globalLocations: {
    updated: 0,
    type: 'Feature',
    features: {
      general: [],
      indoorNormal: [],
      indoorSpectacular: [],
      mobileNormal: [],
      mobileSpectacular: [],
      outdoorNormal: [],
      outdoorSpectacular: [],
    },
  },
  reloadJwt: 0,
  setReloadJwt: () => null,
  setUser: () => null,
  setGlobalLocations: () => null,
  dispatch: () => null,
  notify: () => null,
});

export const AppProvider = ({ children }: any) => {
  const [user, setUser] = useState<null>(null);
  const [reloadJwt, setReloadJwt] = useState<number>(0);
  const [globalLocations, setGlobalLocations] = useState<MapJsonParsed>({
    updated: 0,
    type: 'Feature',
    features: {
      general: [],
      indoorNormal: [],
      indoorSpectacular: [],
      mobileNormal: [],
      mobileSpectacular: [],
      outdoorNormal: [],
      outdoorSpectacular: [],
    },
  });
  const [state, dispatch] = useReducer(AppReducer, appInitialState);
  const notify = (message: string, type: NotifyType, position?: any, headline?: string, autoClose: boolean = false) => {
    toast(<ToastComponent message={message} headline={headline} type={type} />, {
      position: position ? position : 'top-right',
      autoClose: autoClose ? false : 3000,
      className: `${type}_class`,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,

      draggable: true,
      progress: undefined,
      onClose: () => onClosePaymentAlert(autoClose),
    });
  };
  const router = useRouter();

  const onClosePaymentAlert = (autoClose: boolean): void => {
    if (autoClose) {
      let date = new Date();
      const cookieName = 'invalid_payment_methods';
      const daysToExpire = 1;
      const cookieValue = '1';
      const result = date.setDate(date.getDate() + daysToExpire);
      setCookie(null, cookieName, cookieValue, {
        expires: new Date(result),
      });
    }
  };

  useEffect(() => {
    if (router.pathname.startsWith('/publish') && router.pathname !== '/publish/basic') {
      document.getElementsByTagName('body')[0].classList.add('publish');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('publish');
    }
  }, [router.pathname]);

  return (
    <AppContext.Provider
      value={{ user, setUser, state, dispatch, notify, globalLocations, setGlobalLocations, reloadJwt, setReloadJwt }}
    >
      <ToastContainer />
      {typeof window === 'object' && <NotificationProvider>{children}</NotificationProvider>}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  return useContext(AppContext);
};
