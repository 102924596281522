// import '@zendeskgarden/css-bedrock/dist/index.css';
import '../styles/import.scss';
import '../styles/base.scss';
import '../styles/style.scss';
import nookies from 'nookies';

import { AppProvider, useApp } from 'components/app';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import { LanguageProvider } from '../context/language/language.provider';
import { PublishProvider } from '../context/publish/publish.provider';
import App, { AppProps as NextAppProps } from 'next/app';

import { messages } from '../site-settings/site-translation/messages';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import MainLayout from 'components/MainLayout';
import React, { useEffect } from 'react';
import { Content } from 'components/layout';
import ReactTooltip from 'react-tooltip';
import { SessionProvider } from 'next-auth/react';
import Link from 'next/link';
import PageNotFound from './500';
import { useRouter } from 'next/router';
import { MapContext, useMapContext } from 'context/publish/map.provider';
import useMapState from 'context/MapState';
import useOnboardingContext, { OnboardingContextProvider } from 'context/OnboardingContext';

const maxAge = 30 * 24 * 60 * 60;

const MyApp = ({ Component, pageProps, session }: any) => {
  const queryClientRef = React.useRef<QueryClient | null>(null);
  const [isTooltipVisible, setTooltipVisibility] = React.useState(false);
  const [isExternalPage, setIsExternalPage] = React.useState(false);

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }
  const router = useRouter();
  const ctx = useMapState();

  useEffect(() => {
    if (!router.asPath.includes('auth') && !router.asPath.includes('password-reset')) {
      nookies.set(null, 'redirect', router.asPath || '/', {
        maxAge,
        path: '/',
      });
    }
    if (router.pathname.includes('/map-website')) {
      setIsExternalPage(true);
    }
  }, [router.asPath]);

  useEffect(() => {
    const mode = localStorage.getItem('mode');

    if (mode) {
      switchMode(mode);
    } else {
      switchMode('darkMode');
    }

    window.addEventListener('storage', event => {
      if (event.key === 'mode') {
        const actualMode = event.newValue;
        if (actualMode) switchMode(actualMode);
      }
    });

    // localStorage.removeItem('searchMaxPrice');
    // localStorage.removeItem('searchMinPrice');
    localStorage.removeItem('searchText');
    localStorage.removeItem('searchCheckbox');
    // localStorage.removeItem('searchTags');
    // localStorage.removeItem('coming_soon');
    // localStorage.removeItem('has_image');
    setTooltipVisibility(true);
  }, []);

  const switchMode = (mode: string) => {
    const html = document.querySelector('html');
    if (mode === 'darkMode') {
      html?.classList.add('dark');
      localStorage.setItem('mode', 'darkMode');
    } else {
      html?.classList.remove('dark');
      localStorage.setItem('mode', 'lightMode');
    }
  };

  const { setGlobalLocations } = useApp();

  return (
    <>
      <DefaultSeo titleTemplate="%s" />
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"></meta>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      </Head>
      <QueryClientProvider client={queryClientRef.current}>
        <Hydrate state={pageProps.dehydratedState}>
          <LanguageProvider messages={messages}>
            <AppProvider>
              <PublishProvider>
                <MapContext.Provider value={ctx}>
                  <OnboardingContextProvider>
                    <MainLayout>
                      {!isExternalPage && (
                        <>
                          {!pageProps.login || !pageProps.isNew ? (
                            <div className="tps-blindspot-alert bg-dark-500 dark:bg-dark-200">
                              Earn Cash & Credits by recommending Blindspot!
                              <a href="https://referral.seeblindspot.com/" target="_blank" rel="noreferrer">
                                <p className="cursor-pointer seewhat pre-mobile:hidden">Learn more...</p>
                                <p className="cursor-pointer seewhat hidden pre-mobile:block">See more..</p>
                              </a>
                            </div>
                          ) : (
                            <div className="cursor-pointer tps-blindspot-alert bg-dark-500 dark:bg-dark-200">
                              <div
                                data-tip
                                data-for="profile-alert"
                                className="flex items-center justify-center w-5 h-5 mb-1 mr-4 cursor-pointer excl-mark-footer pulse-disclaimer"
                              >
                                <img src="/images/info-icon.svg" />
                              </div>
                              <p data-tip data-for="profile-alert" className="mobile:hidden">
                                Please add your account details -{' '}
                              </p>
                              <p data-tip data-for="profile-alert" className="hidden mobile:block text-sm">
                                Add your account details -{' '}
                              </p>
                              <Link href="/profile/organization" prefetch={false}>
                                <a rel="noreferrer">
                                  <p className="ml-2 font-semibold underline cursor-pointer text-primarydark-default hover:text-white mobile:hidden">
                                    update account settings.
                                  </p>
                                  <p className="ml-2 font-semibold underline cursor-pointer text-primarydark-default hover:text-white hidden mobile:block text-sm">
                                    update account.
                                  </p>
                                </a>
                              </Link>
                              {pageProps.login && isTooltipVisible && (
                                <ReactTooltip id="profile-alert" place="right" className="custom-tooltip">
                                  <div className="flex flex-col items-center justify-center preview-media-tooltip">
                                    <div className="mb-1">Your account is not completed yet.</div>
                                    <div>
                                      You will not be able to publish a campaign until you complete your billing details.
                                    </div>
                                  </div>
                                </ReactTooltip>
                              )}
                            </div>
                          )}
                          <Content>
                            <SessionProvider session={session}>
                              <Component {...pageProps} />
                              {/* <PageNotFound></PageNotFound> */}
                            </SessionProvider>
                          </Content>
                          <div className="footer" />
                        </>
                      )}
                      {isExternalPage && (
                        <Content>
                          <SessionProvider session={session}>
                            <Component {...pageProps} />
                            {/* <PageNotFound></PageNotFound> */}
                          </SessionProvider>
                        </Content>
                      )}
                    </MainLayout>
                  </OnboardingContextProvider>
                </MapContext.Provider>
              </PublishProvider>
            </AppProvider>
          </LanguageProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
};

MyApp.getInitialProps = async (appctx: any) => {
  const appProps = await App.getInitialProps(appctx);
  const { ctx } = appctx;
  if (ctx.req) {
    const { pathname } = ctx;
    const host = ctx.req.headers.host?.toString();
    if (!`${host}`.includes('localhost')) {
      if (!ctx.req.headers['x-forwarded-proto']) {
        ctx.res
          .writeHead(301, {
            Location: 'https://portal.seeblindspot.com/',
          })
          .end();
      }
    }
  }
  return {
    pageProps: {
      ...appProps,
    },
  };
};
export default MyApp;
