import react, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react-with-api';
import Link from 'next/link';
import { JWT } from '@api/users';
import { useApp } from 'components/app';
import { Button, StatusTag } from 'components/common/atoms';

import { oneLine } from 'common-tags';
type IProps = {
  isDisable: boolean;
  isInList: boolean;
  showSoldoutStatus?: boolean;
  loading?: boolean;
  addLocation: () => void;
};


const AddtoCart = ({ isDisable = true, addLocation, isInList = false, showSoldoutStatus, loading }: IProps) => {
  const [canAdd, setCanAdd] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { notify } = useApp();

  useEffect(() => {
    let user = JWT.getJwtUser();
    if (user.role === 'admin')
      setIsAdmin(true);

    setCanAdd(isInList);
    return () => { };
  }, [isInList]);

  const bookUnavailable = (
    <div
      onClick={() => {
        if(!loading) {
          addLocation();
          notify(canAdd ? 'Screen was REMOVED by ADMIN' : 'Screen was ADDED by ADMIN', 'success', 'top-right');
        }
      }}
      className={oneLine`absolute ease-in-out transition shadow-sm rounded-full top-2 right-2 flex items-center justify-end`}
    >
      {canAdd ? (
        <Button buttonSize="sm" fill="outline">
          Remove
        </Button>
      ) : (
        <Button buttonSize="sm">Book</Button>
      )}
    </div>
  );

  if (isDisable)
    return showSoldoutStatus ? (
      <>
        {isAdmin && bookUnavailable}
      </>
    ) : (
      <>
        <div className={oneLine`soldout pointer-events-none flex flex-row items-center justify-center `}>
          <img src="/images/sold-out.svg" loading="lazy" className="object-contain w-9/12" />
        </div>

        {isAdmin && bookUnavailable}
      </>
    );

  return (
    <div
      onClick={(e) => {
        if (!isDisable) {
          e.stopPropagation();
          if(!loading)
          addLocation();
        }
      }}
      className={oneLine`absolute ease-in-out transition shadow-sm rounded-full top-2 right-2 flex items-center justify-end`}
    >
      {canAdd ? (
        <Button disabled={loading} buttonSize="sm" fill="outline">
          Remove
        </Button>
      ) : (
        <Button disabled={loading} buttonSize="sm">Book</Button>
      )}
    </div>
  );
};
export default AddtoCart;
