import React, { useState } from 'react';
import { Icon } from '@iconify/react-with-api';
import { Input, Checkbox } from '../atoms';
import { oneLine } from 'common-tags';
import Popover from '../atoms/popover/popover';
import { ICampaignStatus } from 'interface/ICampaign';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ChevronDown, Filter2 } from 'react-iconly';
import { red } from '@material-ui/core/colors';

type IProps = {
  onSearch(filters: string[]): void;
};
const checkboxes = [
  {
    name: 'Completed',
    value: 'completed',
  },
  {
    name: 'Pending',
    value: 'pending',
  },
  {
    name: 'Processing',
    value: 'processing',
  },
  {
    name: 'Running',
    value: 'running',
  },
  {
    name: 'Paused',
    value: 'paused',
  },
  {
    name: 'Rejected',
    value: 'rejected',
  },
  {
    name: 'Draft',
    value: 'draft',
  },
];

type IPropsCategoryMenu = {
  filters: any;
  setFilters: (filter: string[]) => void;
};
const CategoryMenu = ({ filters, setFilters }: IPropsCategoryMenu) => {
  const isChecked = (value: string): boolean => {
    return filters.indexOf(value) > -1;
  };

  return (
    <ul className="popoever-cart-menu-items">
      {checkboxes.map((el, index: number) => {
        return (
          <li key={index}>
            <Checkbox
              name="primary"
              defaultChecked={isChecked(el.value)}
              onChange={(event: any) => {
                const f = [...filters];
                if (event.target.checked) {
                  f.push(el.value);
                } else {
                  let i = f.indexOf(el.value);
                  if (i > -1) {
                    f.splice(i);
                  }
                }
                setFilters(f);
              }}
              labelColor="dark"
              label={el.name}
            />
          </li>
        );
      })}
    </ul>
  );
};
const FilterCampaignList = ({ onSearch }: IProps) => {
  const [showSearch, setShowSearch] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<string[]>([]);
  const router = useRouter();

  React.useEffect(() => {
    if (router.query && router.query.filters && router.query.filters.toString().length > 0) {
      setFilters(router.query.filters.toString().split(','));
      onSearch(router.query.filters.toString().split(','));
      //setDefaultValue(router.query.q.toString())
      //onSearch(router.query.q.toString())
    }
  }, []);
  return (
    <>
      <Popover
        className="rightbottom"
        handler={
          <>
            <div
              className={`input-simulate hidden md:flex items-center justify-between text-body dark:text-dark-300  flex-1 ${
                showSearch ? 'active' : ''
              }`}
              onClick={() => {
                setShowSearch(!showSearch);
              }}
            >
              <label className="cursor-pointer dark:text-dark-400">
                Sort by
                <span className="mr-2 text-primary-500 text-bold capitalize-fl">
                  &nbsp;
                  {router.query && router.query.filters
                    ? router.query.filters.toString().split(',').length <= 1
                      ? router.query.filters.toString().split(',')
                      : router.query.filters.toString().split(',').length + ' Statuses'
                    : 'All Campaigns'}
                </span>
              </label>
                <ChevronDown set="light" size="small" />
            </div>
            <div className="box-content px-4 py-3 border dark:border-dark-400 md:hidden text-body dark:text-dark-400 rounded-3xl">
              <Filter2 set="light" />
            </div>
          </>
        }
        content={
          <CategoryMenu
            filters={filters}
            setFilters={f => {
              setFilters(f);
              onSearch(f);
            }}
          />
        }
      />
    </>
  );
};
FilterCampaignList.defaultProps = {
  validation: {},
};
export default FilterCampaignList;
