interface IMapTooltipColor {
  stock: { value: number; color: string };
  isListItem?: boolean;
}
import ReactTooltip from 'react-tooltip';
import { StatusTag } from 'components/common/atoms';

const MapTooltipColor = ({ stock, isListItem }: IMapTooltipColor) => {
  if (stock && stock.color === 'green') {
    return (
      <div className="items-center justify-center w-full text-white ">
        <ReactTooltip id="main" place="top" type="dark" effect="solid" multiline={false} className="w-64 text-center" />
        <StatusTag
          hasCustomText={true}
          text={isListItem ? "Available" : "Available - 480+ requests in 48 hours"}
          status="completed"
          data-for="main"
          data-tip="Text for high number of request"
          data-iscapture="true"
        />
      </div>
    );
  }
  if (stock && stock.color === 'yellow') {
    return (
      <div className="items-center justify-center w-full text-white">
        <ReactTooltip id="main" place="top" type="dark" effect="solid" multiline={false} className="w-64 text-center" />
        <StatusTag
          hasCustomText={true}
          text={isListItem ? "Availability varies" : "Availability varies - 100-250 requests in 48h"}
          status="varies"
          data-for="main"
          data-tip="Text for medium number of request"
          data-iscapture="true"
        />
      </div>
    );
  }
  if (stock && stock.color === 'orange') {
    return (
      <div className="items-center justify-center w-full text-white">
        <ReactTooltip id="main" place="top" type="dark" effect="solid" multiline={false} className="w-64 text-center" />
        <StatusTag
          hasCustomText={true}
          text={isListItem ? "Almost sold out" : "Almost sold out - 20-100 requests in 48h"}
          status="paused"
          data-for="main"
          data-tip="Text for low number of request"
          data-iscapture="true"
        />
      </div>
    );
  }
  if (stock && stock.color === 'red') {
    return (
      <div className="items-center justify-center w-full text-white">
        <ReactTooltip id="main" place="top" type="dark" effect="solid" multiline={false} className="w-64 text-center" />
        <StatusTag
          hasCustomText={true}
          text={isListItem ? "Sold Out" : "Sold out < 20 requests in 48h"}
          status="rejected"
          data-for="main"
          data-tip="Text for low number of request"
          data-iscapture="true"
        />
      </div>
    );
  }
  if (stock && stock.color === 'soldout') {
    return (
      <div className="items-center justify-center w-full text-white ">
        <ReactTooltip id="main" place="top" type="dark" effect="solid" multiline={false} className="w-64 text-center" />
        <StatusTag
          hasCustomText={true}
          text={isListItem ? "Sold Out" : "Sold out < 20 requests in 48h"}
          status="rejected"
          data-for="main"
          data-tip="Text for low number of request"
          data-iscapture="true"
        />
      </div>
    );
  }
  return <></>;
};
export default MapTooltipColor;
