import React from 'react';
import { oneLine } from 'common-tags';
import Link from 'next/link';
import { CAMPAIGN_STATUS, StatusTag } from '..';
import { CampaignCardPopOver } from 'components/common/molecules';
import { ICampaignStatus } from 'interface';
type IImageBox = {
  image: string;
  uuid: string;
  status: ICampaignStatus;
};
const ImageBox: React.FC<IImageBox> = ({ image, uuid, status }: IImageBox) => {
  return (
    <div className="relative w-full bg-whiteish rounded-3xl">
      <Link href={`${ uuid === '1b5a9446-1e0e-498c-8bed-ee7e31e55b17' ? `/` : status !== 'draft' ? `/campaign/${uuid}` : `publish/basic/?cid=${uuid} `}`}>
        <a>
          <img
            src={
              status === 'processing' || status === 'draft'
                ? '/images/draft.png'
                : `https://storage.googleapis.com/engage-transcoded-videos/${image}_thumb.jpg`
            }
            className={oneLine` rounded-3xl h-48 object-cover w-full mx-auto block dark:hidden`}
          />
          <img
            src={
              status === 'processing' || status === 'draft'
                ? '/images/draft-dark.png'
                : `https://storage.googleapis.com/engage-transcoded-videos/${image}_thumb.jpg`
            }
            className={oneLine` rounded-3xl h-48 object-cover w-full mx-auto hidden dark:block`}
          />
        </a>
      </Link>
      <CampaignCardPopOver status={status} uuid={uuid} otherClasses="absolute top-3 right-3" />
      <StatusTag status={status} text={status} otherClasses="absolute top-3 left-3" />
    </div>
  );
};
export default ImageBox;
