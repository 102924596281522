import { Portal } from 'components/common/atoms/Portal';
import { oneLine } from 'common-tags';
import { ICampaignList } from 'interface';
import { useIntl } from 'react-intl';
import { Button } from 'components/common/atoms';

const DraftSelector = ({
  campaigns,
  onClose,
  onSelect,
}: {
  onClose: () => void;
  onSelect: (campaign: ICampaignList | null) => void;
  campaigns: ICampaignList[];
}) => {
  const intl = useIntl();
  const getLocatonsName = (campaign: ICampaignList) => {
    const locations = campaign.locations || [];
    if (locations.length === 0) {
      return 'No location booked for this draft';
    }
    if (locations.length === 1) {
      return `Already booked: ${locations[0].location.n}`;
    }
    //get first 3 locations
    const firstLocations = locations.slice(0, 2);
    return `Already booked: ${firstLocations.map(location => location.location.n).join(', ')} ${
      locations.length > 3
        ? `+ ${locations.length - 3}  ${locations.length - 3 === 1 ? 'one location' : 'other locations'} 
    `
        : ''
    }`;
  };

  return (
    <Portal otherClass={`max-w-[680px]   w-full mx-auto overflow-hidden `}>
      <div
        className={oneLine`flex flex-col rounded-3xl text-left h-full transform transition-all p-7 w-full overflow-y-hidden`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
          <div className={oneLine`pb-3 flex items-center justify-between w-full border-b border-dark-300 border-opacity-30`}>
            <div className="dark:text-dark-400">Book location</div>
            <span
              className={oneLine`text-lg  font-bold text-body cursor-pointer ease-in-out transition duration-300 hover:rotate-180`}
              aria-hidden="true"
              onClick={() => {
                onClose();
              }}
            >
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.98959 9.01041L21.0104 21.0312M21.0104 9.01041L8.98959 21.0312"
                  stroke="#A7ACB3"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          </div>
          <div className='pt-4'>
            <div className="dark:text-dark-400 font-semibold">Draft Selections</div>
            <p className="dark:text-dark-300 text-base w-full text-left">
              Choose the campaign draft to which you would like to add the selected location:
            </p>
          </div>
          <div className="flex-1 my-4 overflow-y-auto w-full py-2">
            <div className="w-full flex flex-col space-y-4 overflow-y-auto">
              {campaigns.map((campaign, index) => (
                <div
                  key={index}
                  className="relative justify-center w-full cursor-pointer bg-dark-300 bg-opacity-20 rounded-2xl shadow-md border border-dark-300 border-opacity-20 hover:border-primary-500 flex flex-col space-y-1 px-4 py-2"
                  onClick={() => {
                    onSelect(campaign);
                  }}
                >
                    <span className="dark:text-white text-base font-bold">{campaign.name}</span>
                    <span className="dark:text-dark-300 text-base w-full text-left">{getLocatonsName(campaign)}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full">
            <div className='relative mb-2 w-full flex items-center justify-center sync-divider'>
             <div className='h-full w-fit z-10 dark:bg-dark-200 bg-white px-4 dark:text-dark-300'>or</div>
            </div>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                onSelect(null);
              }}
              fullWidth={true}
            >
              {intl.formatMessage({
                defaultMessage: 'Create new campaign',
                id: 'Button.CreateNewCampaign',
              })}
            </Button>
          </div>
        </div>
    </Portal>
  );
};
export default DraftSelector;
