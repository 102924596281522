import React, { useEffect } from 'react'
import { Icon } from '@iconify/react-with-api'
import { Input } from '../atoms'
import { oneLine } from 'common-tags'
import { useRouter } from 'next/router'
import { Search } from 'react-iconly'

type IProps = {
  onSearch(text: string): void
  isOpenSearch(isOpen: boolean): void
}
const SearchBar = ({ onSearch, isOpenSearch }: IProps) => {
  const router = useRouter()

  const [showSearch, setShowSearch] = React.useState<boolean>(false)
  const [defaultValue, setDefaultValue] = React.useState<string>('')
  useEffect(() => {
    if (router.query && router.query.q && router.query.q.length > 0) {
      setDefaultValue(router.query.q.toString())
      onSearch(router.query.q.toString())
    }
  }, [])
  return (
    <>
      <div className={oneLine`flex items-center relative flex-2 w-full`}>
        <div className="search-input-box w-full ">
          <Input
            placeholder="Search"
            name="input1"
            onKeyUp={(event: any) => {
              onSearch(event.target.value)
            }}
            label=""
            startIcon="Search"
            defaultValue={defaultValue}
            fullWidth={true}
          />
        </div>
      </div>
    </>
  )
}
SearchBar.defaultProps = {
  validation: {}
}

export default SearchBar
