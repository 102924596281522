/* eslint-disable no-shadow */
import { BooleanSchema } from 'yup';
import { IMediaUpload } from './IMedia';
import { LocationRestriction } from './IScheduler';
import { DecodedMetadata } from '@utils/map';

export enum ILocationStatus {
  live = 'live',
  commingSoon = 'coming-soon',
}
export type IlocationType = 'indoor' | 'outdoor' | 'mobile';
export type IMapPinType = 'cluster' | 'pin';

export type IContentType = 'video' | 'static';
export type IMediaType = 'wmw' | 'mp4' | 'jpg';
export type ILocationFrequency = {
  seconds: number;
  label: string;
  uuid: string;
};
export enum ILocationSchedule {
  Global = 'global',
  Daily = 'daily',
  Hourly = 'hourly',
}
export enum ILocationContentType {
  Video = 'video',
  Static = 'static',
}

export interface ILocation {
  uuid: string;
  n: string;
  st: ILocationStatus;
  lg: number;
  lt: number;
  tp: IlocationType;
  nId?: string;
  vertRez?: number;
  meta?: DecodedMetadata;
  networkImages?: number;
  locationImages?: number;
  networkId?: string;
}
export interface IMapPins {
  type: IMapPinType;
  total: number;
  uuid: string;
  position: { lat: number; lng: number };
}
export interface ILocationFull {
  meta?: DecodedMetadata;
  nId?: string;
  uuid: string;
  networkUuid: string;
  eId?: boolean;
  lg: number;
  country: string;
  lt: number;
  hidePrice?: boolean;
  vertRez: number;
  avg: string;
  horRez: number;
  minBookingHours: string;
  isHourly: boolean;
  locationPhotos?: string[];
  locationPhoto: string;
  description: string;
  circuit: number;
  sRestrictions: string;
  onboardingStatus: string;
  isSingleCircuit: boolean;
  address: string;
  averagePrice: string;
  tags: [{ name: string }];
  mediaType: IMediaType;
  type: IlocationType[];
  acceptedDurations: number[];
  acceptedSchedules: ILocationSchedule;
  contentType: ILocationContentType[];
  frequencies: ILocationFrequency[];
  locationImages: number;
  networkImages: number;
  openingHoursStart?: number;
  openingHoursEnd?: number;
  nonStop: boolean;
  network: {
    uuid: string;
    ratecardPlayrate: number;
  };
  networkId: string;
  isMultiScreen: boolean;
  stock: { value: number; color: string };
  campaignFrequency: number;
  n: string;
  name: string;
  tlp: number[];
  pphGroup?: string;
  formatGroupId?: string;
  tz: string;
  scheduleRestriction: LocationRestriction[];
  scheduleGroup?: string;
  multiScreenUrl?: string;
  rt: number;
  pC: boolean;
  otherRestriction?: any;
  avgImpression?: any;
}

export interface ICampaignPublishPph {
  pph: number;
  locations: string[];
  otherPph: ILocationFrequency[];
  pphGroup: string;
}
export interface ICampaignFormat {
  resolution: [number, number];
  uuid: string;
  media: IMediaUpload[];
  groupId?: string;
  isMultiScreen: boolean;
  multiScreenUrl?: string;
  ratio: string;
  locations: string[];
  locationsName: string[];
  selectedDuration: number;
  contentType: ILocationContentType;
  durations: number[];
  rt: number;
}

export interface IListLocation {
  location: ILocationFull;
  pph: number;
  reach: number;
  totalLocations: number;
  addGlobalSlots?: boolean;
}
export interface IGeoCountries {
  iso: string;
  name: string;
  uuid: string;
  iso3: string;
}
export interface INetflix {
  resolution: string;
  address: string;
  stock: { value: number; color: string };
  avg: string;
  circuit: string;
  network: string;
  name: string;
  uuid: string;
  tags: string[];
  photo: string;
  description: string;
  contentType: string;
  otherRestrictions?: any;
}

export interface Features {
  type: string;
  id: number;

  properties: {
    uuid: string;
    title: string;
    requests: { value: number; color: string };
    hI: boolean;
    status: string;
    search: string;
    locationType: IlocationType[];
    tags?: string[];
    geometry: {
      type: 'Point';
      coordinates: number[];
    };
  };
}
export interface MapJson {
  updated: number;
  type: string;
  features: Features[];
}

export interface MapJsonParsed {
  updated: number;
  type: string;
  features: {
    general: Features[];
    indoorNormal: Features[];
    indoorSpectacular: Features[];
    mobileNormal: Features[];
    mobileSpectacular: Features[];
    outdoorNormal: Features[];
    outdoorSpectacular: Features[];
  };
}
