import React, { useEffect } from 'react';
import { SelectInput } from '../atoms';
import { IOptions } from 'interface/ISelect';
import { ISugestion } from 'interface/ISugestion';
import Address from 'api/address';
import { string } from '@amcharts/amcharts4/core';
type ISource_Type = 'here' | 'here-auth' | 'poi';
type ITypeProps = {
  hasDefaultValue?: boolean;
  source: ISource_Type;
  api: null;
  onChange: (el: any) => void;
  innerRef?: any;
  validation?: any;
  label?: string;
  placeholderText: string;
  fullWidth: boolean;
  selName: string;
  menuPlacement?: string;
  borderLight?: boolean;
  defaultValue?: any;
  resultType?: string;
  sourceData?: any[];
  hint?: string;
  countryArea?: string;
  suggestionsType?: string;
  disabled?: boolean;
  dataCy?: string;
  isInline?: boolean;
};
const options: IOptions[] = [];

const AddressField = ({
  hasDefaultValue,
  source,
  label,
  menuPlacement,
  api,
  innerRef,
  onChange,
  validation,
  isInline,
  placeholderText,
  fullWidth,
  selName,
  borderLight,
  defaultValue,
  resultType,
  sourceData,
  hint,
  countryArea,
  suggestionsType,
  disabled,
  dataCy,
}: ITypeProps) => {
  const [localSavedSearch, setLocalSavedSearch] = React.useState<any>(
    localStorage.getItem('searchLocObj') && !isInline ? JSON.parse(localStorage.getItem('searchLocObj') || '') : null
  );
  useEffect(() => {
    if (localStorage.getItem('searchLocObj') && !isInline) {
      setLocalSavedSearch(JSON.parse(localStorage.getItem('searchLocObj') || ''));
      JSON.parse(localStorage.getItem('searchLocObj') || '');
    } else {
      setLocalSavedSearch(null);
    }
  }, [localStorage.getItem('searchLocObj')]);

  const query = async (q: string) => {
    switch (source) {
      case 'here':
        return await Address.queryHere(q, resultType, countryArea, suggestionsType);
      case 'here-auth':
        return await Address.queryHereAuth(q, sourceData);
      case 'poi':
        return await Address.getPoi(resultType as string, q);
    }
  };

  const getAsyncOptions = async (item: any) => {
    let split = item.split(',');
    if (isNaN(split[0])) {
      const c = await query(item);
      return c;
    } else {
      return [
        {
          hasGeocode: true,
          label: item,
          value: item,
          latitude: split[0],
          longitude: split[1],
        },
      ];
    }
  };

  const onChangeData = async (data: ISugestion) => {
    if (data) {
      localStorage.setItem('searchLocObj', JSON.stringify(data));
      setLocalSavedSearch(data);
      if (!data.hasGeocode) {
        const response = await Address.reverseGeoCode(data.locationId);
        data.latitude = response.latitude;
        data.longitude = response.longitude;
        data.topLeft = response.topLeft;
        data.bottomRight = response.bottomRight;
        data.iso3 = response.iso3;
      }
      onChange(data);
    } else {
      localStorage.removeItem('searchLocObj');
      onChange(null);
    }
  };
  return (
    <div className="w-full" key={localSavedSearch}>
      <SelectInput
        defaultValue={hasDefaultValue ? defaultValue : localSavedSearch}
        borderLight={borderLight}
        isAsync={true}
        classNamePrefix="transparent"
        isMulti={false}
        isClearable={true}
        onChange={onChangeData}
        selName={selName}
        menuPlacement={menuPlacement}
        fullWidth={fullWidth}
        placeholderText={placeholderText}
        options={options}
        validation={validation}
        getAsyncOptions={getAsyncOptions}
        label={label}
        hint={hint}
        disabled={disabled ? true : false}
        innerRef={innerRef}
        dataCy={dataCy}
      />
    </div>
  );
};
AddressField.defaultProps = {
  source: 'here',
  validation: {},
};

export default AddressField;
